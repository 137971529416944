<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
// import Vue from 'vue';
import GoogleMapsApiLoader from 'google-maps-api-loader';

export default {
  name: 'google-map-loader',
  props: {
    region: String,
    mapConfig: Object,
    apiKey: String,
    addresMap: String,
    country: String,
  },
  data() {
    return {
      google: null,
      map: null,
    };
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey,
    });
    this.google = googleMapApi;
    this.initializeMap();
    this.decodeAddres(this.addresMap, this.region);
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(
        mapContainer, this.mapConfig,
      );
    },
    decodeAddres(address, region) {
      const geocoder = new this.google.maps.Geocoder();
      const resultsMap = this.map;
      geocoder.geocode({
        address,
        region,
      }, (results, status) => {
        if (status === 'OK') {
          resultsMap.setCenter(results[0].geometry.location);
          // eslint-disable-next-line no-new
          new this.google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location,
          });
        } else {
          alert(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    },
  },
};
</script>
<style scoped>
  .google-map {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
</style>
